import { memo } from 'react';
import {
    faArrowRightToBracket,
    faCodeFork,
    faFloppyDisk,
    faShare,
    faSquareArrowUpRight,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Navbar.module.scss';
import Button from './Button';
import ProjectTitle from './ProjectTitle';

const Navbar = memo(({ project, onShare, onEditInfo }) => {
    return (
        <nav className={styles.wrapper}>
            <div className={styles.logo}>
                <strong>F8</strong>
            </div>

            <Button icon={faFloppyDisk}>Save</Button>
            <Button icon={faCodeFork}>Fork</Button>
            <Button icon={faShare} onClick={onShare}>
                Share
            </Button>

            {project && <ProjectTitle title={project?.title} onEditInfo={onEditInfo} />}

            <Button className={styles.pullRight} href="/" target="_blank" icon={faSquareArrowUpRight}>
                Open in new tab
            </Button>
            <Button icon={faArrowRightToBracket}>Close</Button>
        </nav>
    );
});

export default Navbar;
