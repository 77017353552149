import { useCallback, useEffect } from 'react';
import { useWebContainer } from '@/context/WebContainer';
import { files } from './files';
import webContainerService from '@/services/WebContainerService';

function StartWebContainer() {
    const { boot, webContainer } = useWebContainer();

    const installDependencies = useCallback(async () => {
        const installProcess = await webContainer.spawn('npm', ['install']);

        webContainerService.writeLog(installProcess);

        return installProcess.exit;
    }, [webContainer]);

    const startDevServer = useCallback(async () => {
        const serverProcess = await webContainer.spawn('npm', ['run', 'start']);

        webContainerService.writeLog(serverProcess);
    }, [webContainer]);

    useEffect(() => {
        const handle = () => {
            document.dispatchEvent(new CustomEvent('terminal-fit'));
        };
        window.addEventListener('resize', handle);

        return () => window.removeEventListener('resize', handle);
    }, []);

    // Booting
    useEffect(() => {
        boot();
    }, [boot]);

    // Setting up
    useEffect(() => {
        if (!webContainer) return;

        const start = async () => {
            // Mount files
            await webContainer.mount(files);

            // Install dependencies
            // const exitCode = await installDependencies();
            // if (exitCode !== 0) {
            //     throw new Error('Installation failed');
            // }

            // Start dev server
            // await startDevServer();

            const shellProcess = await webContainerService.startShell();
            document.addEventListener('terminal-fit', () => {
                const terminal = webContainerService.getTerminal();

                shellProcess.resize({
                    cols: terminal.cols,
                    rows: terminal.rows,
                });
            });
        };
        start();
    }, [webContainer, installDependencies, startDevServer]);

    return null;
}

export default StartWebContainer;
