import debounce from 'lodash.debounce';

class EditorService {
    _editor;
    _monaco;

    setEditor(editor, monaco) {
        this._editor = editor;
        this._monaco = monaco;
    }

    getEditor() {
        return this._editor;
    }

    getMonaco() {
        return this._monaco;
    }

    layout = debounce(() => {
        this._editor && this._editor.layout();
    }, 10);
}

const editorService = new EditorService();

export default editorService;
