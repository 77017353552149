import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import styles from './Button.module.scss';

function Button({
    icon,
    children,
    className,
    href,
    loading = false,
    disabled = false,
    type: Type = 'button',
    target = '_self',
    onClick,
}) {
    const _props = {};
    if (href) {
        Type = 'a';
        _props.href = href;
        _props.target = target;
    }
    return (
        <Type
            {..._props}
            className={clsx(styles.wrapper, className, { [styles.disabled]: loading || disabled })}
            onClick={onClick}
        >
            <FontAwesomeIcon
                icon={loading ? faSpinner : icon}
                className={clsx(styles.icon, { [styles.loading]: loading })}
            />
            {children}
        </Type>
    );
}

export default Button;
