class WebContainerService {
    _webContainer;
    _terminal;

    setWebContainer(webContainer) {
        this._webContainer = webContainer;
    }

    setTerminal(terminal) {
        this._terminal = terminal;
    }

    getTerminal() {
        return this._terminal;
    }

    writeLog(process) {
        process.output.pipeTo(
            new WritableStream({
                write: (data) => {
                    this._terminal.write(data);
                },
            }),
        );
    }

    async startShell() {
        const shellProcess = await this._webContainer.spawn('jsh', {
            terminal: {
                cols: this._terminal.cols,
                rows: this._terminal.rows,
            },
        });
        shellProcess.output.pipeTo(
            new WritableStream({
                write: (data) => {
                    this._terminal.write(data);
                },
            }),
        );

        const input = shellProcess.input.getWriter();
        this._terminal.onData((data) => {
            input.write(data);
        });

        return shellProcess;
    }
}

const webContainerService = new WebContainerService();

export default webContainerService;
