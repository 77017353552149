import { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import '@/styles/overlayscrollbars.scss';
import styles from './Navigation.module.scss';
import TabItem from './TabItem';

function Navigation() {
    const [files, setFiles] = useState([
        {
            id: 1,
            name: 'index.html',
        },
        {
            id: 2,
            name: 'styles.css',
        },
        {
            id: 3,
            name: 'main.js',
        },
        {
            id: 4,
            name: 'package.json',
        },
        {
            id: 5,
            name: 'package-lock.json',
        },
    ]);
    const [current, setCurrent] = useState(files[0]);

    return (
        <header className={styles.wrapper}>
            <OverlayScrollbarsComponent
                defer
                element="nav"
                className={styles.nav}
                options={{ scrollbars: { autoHide: 'move' } }}
            >
                <ReactSortable tag="ul" className={styles.fileList} list={files} setList={setFiles}>
                    {files.map((file) => (
                        <TabItem
                            key={file.id}
                            name={file.name}
                            active={current.id === file.id}
                            onMouseDown={() => setCurrent(file)}
                        />
                    ))}
                </ReactSortable>
            </OverlayScrollbarsComponent>
        </header>
    );
}

export default Navigation;
