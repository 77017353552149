import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Tabs.module.scss';

function Tab({ title, icon, current, onClick }) {
    return (
        <button className={clsx(styles.tab, { [styles.current]: current })} onClick={onClick}>
            <FontAwesomeIcon icon={icon} className={styles.icon} />
            {title}
        </button>
    );
}

export default Tab;
