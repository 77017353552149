import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.scss';
import './styles/toastify.scss';
import Home from './pages/Home';
import Project from './pages/Project';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/edit/:projectId',
        element: <Project />,
    },
]);

function App() {
    return (
        <>
            <RouterProvider router={router} />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                pauseOnFocusLoss={false}
                closeButton={false}
                closeOnClick
                rtl={false}
                draggable
                theme="colored"
            />
        </>
    );
}

export default App;
