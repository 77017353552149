import { getVSIFileIcon, getVSIFolderIcon } from 'file-extension-icon-js';
import styles from './TreeItem.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

function TreeItem({ node, style, dragHandle, tree }) {
    const getIcon = (node) => {
        switch (node.data.type) {
            case 'folder':
                return (
                    <img
                        className={styles.icon}
                        src={getVSIFolderIcon(node.data.name, node.isOpen)}
                        alt={node.data.name}
                        width="16"
                    />
                );
            case 'file':
                return (
                    <img className={styles.icon} src={getVSIFileIcon(node.data.name)} alt={node.data.name} width="16" />
                );
            default:
        }
    };

    return (
        <div
            className={clsx(styles.wrapper, node.state, {
                [styles.selected]: node.isSelected,
                [styles.highlight]:
                    tree.dragDestinationParent?.isAncestorOf(node) &&
                    tree.dragDestinationParent?.id !== tree.dragNode?.parent?.id,
            })}
            style={style}
            ref={dragHandle}
            onClick={() => node.toggle()}
        >
            <span className={clsx(styles.arrowWrap, { [styles.hide]: node.data.type !== 'folder' })}>
                <FontAwesomeIcon className={styles.arrow} icon={node.isOpen ? faChevronDown : faChevronRight} />
            </span>
            {getIcon(node)}
            <span className={styles.itemName}>{node.data.name}</span>
        </div>
    );
}

export default TreeItem;
