import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const projectApi = createApi({
    reducerPath: 'projectApi',
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: () => 'projects',
        }),
        getOneProject: builder.query({
            query: (id) => `code-projects/${id}`,
        }),
    }),
});

export const { useGetProjectsQuery, useGetOneProjectQuery } = projectApi;
