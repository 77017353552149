import { useRef } from 'react';
import Modal from 'react-modal';
import { faDesktop, faEarth } from '@fortawesome/free-solid-svg-icons';

import styles from './ShareModal.module.scss';
import { Tab, Tabs } from './Tabs';
import ShareTab from './ShareTab';
import EmbedTab from './EmbedTab';

function ShareModal({ project, isOpen, onRequestClose }) {
    const overlayRef = useRef();

    const handleClose = () => {
        overlayRef.current.classList.add(styles.closing);
        overlayRef.current.addEventListener('animationend', onRequestClose, { once: true });
    };

    return (
        <Modal
            isOpen={isOpen}
            overlayRef={(ref) => (overlayRef.current = ref)}
            className={styles.content}
            overlayClassName={styles.overlay}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
            ariaHideApp={false}
        >
            <Tabs>
                <Tab title="Share" icon={faEarth}>
                    <ShareTab project={project} />
                </Tab>
                <Tab title="Embed" icon={faDesktop}>
                    <EmbedTab />
                </Tab>
            </Tabs>
        </Modal>
    );
}

export default ShareModal;
