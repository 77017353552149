import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Form.module.scss';

function Checkbox({ checked, label, icon, requiredLabel, onChange }) {
    return (
        <label className={styles.checkbox}>
            <input className={styles.input} type="checkbox" checked={checked} onChange={onChange} />
            <span className={styles.label}>
                {icon && (
                    <span className={styles.icon}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}
                {label}
                {!!requiredLabel && <span className={styles.requiredLabel}>{requiredLabel}</span>}
            </span>
        </label>
    );
}

export default Checkbox;
