import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
// import logger from 'redux-logger'
import thunk from 'redux-thunk';

// Services
import { projectApi } from './services/projectApi';

// Reducers

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};

const rootReducer = combineReducers({
    [projectApi.reducerPath]: projectApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk, projectApi.middleware],
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
