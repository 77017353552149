import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Form.module.scss';
import clsx from 'clsx';

const Button = forwardRef(({ children, icon, className = '', ...props }, ref) => {
    return (
        <button {...props} ref={ref} className={clsx(styles.button, [className])}>
            {icon && <FontAwesomeIcon icon={icon} />}
            {children}
        </button>
    );
});

export default Button;
