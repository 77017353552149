import { useCallback, useState } from 'react';
import { WebContainer } from '@webcontainer/api';
import webContainerService from '@/services/WebContainerService';
import Context from './Context';

function Provider({ children }) {
    const [webContainer, setWebContainer] = useState(null);

    const boot = useCallback(async () => {
        const webContainer = await WebContainer.boot();
        webContainerService.setWebContainer(webContainer);
        setWebContainer(webContainer);
        return webContainer;
    }, []);

    const value = {
        boot,
        webContainer,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default Provider;
