import styles from './Form.module.scss';

function Label({ children, ...props }) {
    return (
        <label className={styles.label} {...props}>
            {children}
        </label>
    );
}

export default Label;
