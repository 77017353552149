import { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { isSameOrigin, isUrlValid } from '@/helpers/strings';
import { useWebContainer } from '@/context/WebContainer';
import styles from './Browser.module.scss';

const Browser = memo(({ isResizing }) => {
    const [url, setUrl] = useState('');
    const [urlTmp, setUrlTmp] = useState('');
    const inputRef = useRef();
    const iframeRef = useRef();
    const originUrlRef = useRef();

    const { webContainer } = useWebContainer();

    useEffect(() => {
        if (webContainer) {
            webContainer.on('server-ready', (port, url) => {
                iframeRef.current.src = url;
                originUrlRef.current = url;
                setUrl(url);
                setUrlTmp(url);
            });
        }
    }, [webContainer]);

    const access = (url) => {
        iframeRef.current.src = url;
    };

    useEffect(() => {
        access(url);
    }, [url]);

    const handleReload = () => {
        access(url);
    };

    const handleAccess = (e) => {
        if (e.key === 'Enter') {
            if (!isUrlValid(urlTmp)) return;

            if (isSameOrigin(originUrlRef.current, urlTmp)) {
                setUrl(urlTmp);

                // Reload when entering the input without changing the url.
                if (url === urlTmp) {
                    handleReload();
                }
            }
            // Reset the previous url, if the user enters a cross domain.
            else {
                toast.error('Cross-domains are not accessible', { autoClose: 1500 });
            }

            // Always blur from input after pressing enter.
            inputRef.current.blur();
        }
    };

    return (
        <div className={clsx(styles.wrapper, { [styles.readonly]: isResizing })}>
            <header className={styles.header}>
                <button className={styles.reloadBtn} onClick={handleReload}>
                    <FontAwesomeIcon icon={faRotateRight} />
                </button>
                <div className={styles.addressBar}>
                    {urlTmp.startsWith('https://') && isUrlValid(urlTmp) && (
                        <FontAwesomeIcon className={styles.tls} icon={faLock} />
                    )}
                    <input
                        ref={inputRef}
                        className={styles.input}
                        value={urlTmp}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => setUrlTmp(e.target.value)}
                        onKeyDown={handleAccess}
                    />
                </div>
            </header>
            <iframe
                ref={iframeRef}
                className={styles.browser}
                src={url}
                title="Preview"
                allow="geolocation; microphone; camera; payment; autoplay; serial; cross-origin-isolated"
            />
        </div>
    );
});

export default Browser;
