import { memo } from 'react';
import styles from './Sidebar.module.scss';
import FileManager from './FileManager';
import Collapsed from './Collapsed';
import Info from './Info';
import Dependency from './Dependency';

const Sidebar = memo(({ project, onMove }) => {
    return (
        <aside className={styles.wrapper}>
            <header className={styles.header}>PROJECT</header>

            <Collapsed title="INFO" defaultExpanded>
                <Info project={project} />
            </Collapsed>

            <Collapsed title="FILES" defaultExpanded>
                {project && <FileManager data={project.files} onMove={onMove} />}
            </Collapsed>

            <Collapsed title="DEPENDENCIES" defaultExpanded>
                <Dependency />
            </Collapsed>
        </aside>
    );
});

export default Sidebar;
