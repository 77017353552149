import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import SplitPane from 'react-split-pane';

import { useGetOneProjectQuery } from '@/redux/services/projectApi';

import styles from './Project.module.scss';
import Navbar from '@/components/Project/Navbar';
import ActivityBar from '@/components/Project/ActivityBar';
import Sidebar from '@/components/Project/Sidebar';
import Editor from '@/components/Project/Editor';
import Browser from '@/components/Project/Browser';
import Terminal from '@/components/Project/Terminal';
import Console from '@/components/Project/Console';
import ShareModal from '@/components/Project/ShareModal';
import EditInfoModal from '@/components/Project/EditInfoModal';
import editorService from '@/services/EditorService';
import StartWebContainer from './StartWebContainer';

const SHARE_MODAL = 'SHARE_MODAL';
const EDIT_INFO_MODAL = 'EDIT_INFO_MODAL';

function Project() {
    const { projectId } = useParams();
    const { data: project } = useGetOneProjectQuery(projectId);

    const [showModal, setShowModal] = useState(null);
    const [isResizing, setIsStartResize] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            editorService.layout();
            document.dispatchEvent(new CustomEvent('terminal-fit'));
        });
    }, []);

    const hideModal = useCallback(() => setShowModal(null), []);
    const handleDragStarted = useCallback(() => setIsStartResize(true), []);
    const handleDragFinished = useCallback(() => setIsStartResize(false), []);
    const showInfoModal = useCallback(() => setShowModal(EDIT_INFO_MODAL), []);
    const showShareModal = useCallback(() => setShowModal(SHARE_MODAL), []);

    const autoEditorLayout = useCallback(() => {
        editorService.layout();
        document.dispatchEvent(new CustomEvent('terminal-fit'));
    }, []);

    const handleMove = useCallback((data) => {
        console.log(data);
    }, []);

    const projectData = useMemo(() => structuredClone(project?.data), [project]);

    return (
        <div className={styles.wrapper}>
            <Navbar project={projectData} onShare={showShareModal} onEditInfo={showInfoModal} />
            <ActivityBar />

            <main className={styles.main}>
                <SplitPane split="vertical" defaultSize="20%">
                    <Sidebar project={projectData} onMove={handleMove} />

                    <SplitPane
                        split="vertical"
                        defaultSize="60%"
                        onDragStarted={handleDragStarted}
                        onDragFinished={handleDragFinished}
                        onChange={autoEditorLayout}
                    >
                        <SplitPane split="horizontal" defaultSize="70%" onChange={autoEditorLayout}>
                            <Editor />
                            <Terminal />
                        </SplitPane>
                        <SplitPane
                            split="horizontal"
                            defaultSize="70%"
                            onDragStarted={handleDragStarted}
                            onDragFinished={handleDragFinished}
                        >
                            <Browser isResizing={isResizing} />
                            <Console />
                        </SplitPane>
                    </SplitPane>
                </SplitPane>
            </main>

            <StartWebContainer />

            {projectData && (
                <>
                    <ShareModal project={projectData} isOpen={showModal === SHARE_MODAL} onRequestClose={hideModal} />
                    <EditInfoModal
                        project={projectData}
                        isOpen={showModal === EDIT_INFO_MODAL}
                        onRequestClose={hideModal}
                    />
                </>
            )}
        </div>
    );
}

export default Project;
