import { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

import { Label, FormRow, TextInput, Button, AutoComplete, Select, TextArea, Checkbox } from '../../Form';
import styles from './ShareModal.module.scss';
import clsx from 'clsx';

function EmbedTab() {
    const [copied, setCopied] = useState(null);
    const [defaultFile, setDefaultFile] = useState('styles.css');
    const [shareLayout, setShareLayout] = useState('editor-preview');
    const [clickToLoad, setClickToLoad] = useState(false);
    const [hideExplorer, setHideExplorer] = useState(false);
    const [hideNavigation, setHideNavigation] = useState(false);

    const urlRef = useRef();
    const codeRef = useRef();

    const shareUrl = 'https://code.f8team.dev/edit/api-starter-gxrszq?file=README.md';
    const shareCode = `import sdk from '@f8/sdk'

sdk.embedProjectId(
  'elementOrId',
  'f8-code-api-starter-gxrszq',
  {
    forceEmbedLayout: true,${
        defaultFile
            ? `
    openFile: '${defaultFile}',`
            : ''
    }${
        shareLayout !== 'editor-preview'
            ? `
    view: '${shareLayout}',`
            : ''
    }${
        clickToLoad
            ? `
    clickToLoad: true,`
            : ''
    }${
        hideExplorer
            ? `
    hideExplorer: true,`
            : ''
    }${
        hideNavigation
            ? `
    hideNavigation: true,`
            : ''
    }
  }
);`;

    useEffect(() => {
        let id;
        if (copied) {
            id = setTimeout(() => setCopied(null), 3000);
        }
        return () => clearTimeout(id);
    }, [copied]);

    return (
        <div className={styles.inner}>
            <div className={styles.left}>
                <Label htmlFor="share-url">Embed URL</Label>
                <FormRow>
                    <TextInput
                        ref={urlRef}
                        id="embed-url"
                        readOnly
                        value={shareUrl}
                        onFocus={() => urlRef.current.select()}
                    />
                    <CopyToClipboard text={shareUrl} onCopy={() => setCopied('url')}>
                        <Button className={styles.copyBtn} icon={copied === 'url' ? faCheck : faClipboard}>
                            {copied === 'url' ? 'Copied' : 'Copy URL'}
                        </Button>
                    </CopyToClipboard>
                </FormRow>

                <Label>Embed using F8 SDK</Label>
                <FormRow className={styles.codeWrap}>
                    <TextArea
                        ref={codeRef}
                        id="embed-code"
                        readOnly
                        className={styles.shareCode}
                        value={shareCode}
                        onFocus={() => codeRef.current.select()}
                    />
                    <CopyToClipboard text={shareCode} onCopy={() => setCopied('code')}>
                        <Button
                            className={clsx(styles.copyBtn, styles.copyCodeBtn)}
                            icon={copied === 'code' ? faCheck : faClipboard}
                        >
                            {copied === 'code' ? 'Copied' : 'Copy URL'}
                        </Button>
                    </CopyToClipboard>
                </FormRow>
            </div>
            <div className={styles.right}>
                <Label>Default file to show</Label>
                <AutoComplete
                    value={defaultFile}
                    options={['index.html', 'styles.css', 'main.js']}
                    onChange={(option) => setDefaultFile(option)}
                />

                <Label>Editor layout</Label>
                <Select
                    selected={shareLayout}
                    options={[
                        {
                            label: 'Editor & preview',
                            value: 'editor-preview',
                        },
                        {
                            label: 'Editor only',
                            value: 'editor',
                        },
                        {
                            label: 'Preview only',
                            value: 'preview',
                        },
                    ]}
                    onChange={setShareLayout}
                />

                <div className={styles.codeOptions}>
                    <Checkbox label="Click to load" onChange={() => setClickToLoad(!clickToLoad)} />
                    <Checkbox label="Hide file explorer" onChange={() => setHideExplorer(!hideExplorer)} />
                    <Checkbox label="Hide preview URL" onChange={() => setHideNavigation(!hideNavigation)} />
                </div>
            </div>
        </div>
    );
}

export default EmbedTab;
