import { useRef, useState } from 'react';
import Modal from 'react-modal';
import { faEyeSlash, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Label, TextInput, Radio, TextArea } from '../../Form';
import styles from './EditInfoModal.module.scss';

const visibililies = [
    {
        label: 'Public',
        type: 'public',
        icon: faLockOpen,
    },
    {
        label: 'Secret (accessible by URL)',
        type: 'secret',
        icon: faEyeSlash,
    },
    {
        label: 'Private',
        type: 'private',
        icon: faLock,
        requiredLabel: 'PRO USER REQUIRED',
    },
];

function EditInfoModal({ project, isOpen, onRequestClose }) {
    const [title, setTitle] = useState(project.title);
    const [description, setDescription] = useState(project.description);
    const [currentVisibility, setCurrentVisibility] = useState(project.visibility);
    const overlayRef = useRef();

    const handleClose = () => {
        overlayRef.current.classList.add(styles.closing);
        overlayRef.current.addEventListener('animationend', onRequestClose, { once: true });
    };

    return (
        <Modal
            isOpen={isOpen}
            overlayRef={(ref) => (overlayRef.current = ref)}
            className={styles.content}
            overlayClassName={styles.overlay}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
            ariaHideApp={false}
        >
            <div className={styles.inner}>
                <Label htmlFor="title">Title</Label>
                <TextInput
                    id="title"
                    placeholder="Untitled"
                    value={title}
                    maxLength={100}
                    autoFocus
                    onChange={(e) => setTitle(e.target.value)}
                />

                <Label htmlFor="description">Description</Label>
                <TextArea
                    id="description"
                    maxLength={1000}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <Label>Visibility</Label>
                {visibililies.map((visibility) => (
                    <Radio
                        key={visibility.type}
                        {...visibility}
                        checked={visibility.type === currentVisibility}
                        onChange={() => setCurrentVisibility(visibility.type)}
                    />
                ))}
            </div>
        </Modal>
    );
}

export default EditInfoModal;
