import clsx from 'clsx';
import { getVSIFileIcon } from 'file-extension-icon-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '@/styles/overlayscrollbars.scss';
import styles from './Navigation.module.scss';

function TabItem({ active, name, isPreview, onMouseDown }) {
    return (
        <li className={clsx(styles.fileItem, { [styles.active]: active, [styles.preview]: isPreview })}>
            <button className={styles.fileBtn} onMouseDown={onMouseDown}>
                <img className={styles.icon} src={getVSIFileIcon(name)} alt={name} />
                {name}
                <span className={styles.closeBtn} onMouseDown={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </button>
        </li>
    );
}

export default TabItem;
