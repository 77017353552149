import React, { useMemo, useState } from 'react';
import styles from './Tabs.module.scss';

function Tabs({ children }) {
    const [current, setCurrent] = useState(0);
    const tabs = useMemo(
        () =>
            React.Children.map(children, (child, idx) => ({
                ...child,
                props: {
                    ...child.props,
                    current: current === idx,
                    onClick: () => setCurrent(idx),
                },
            })),
        [children, current],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>{tabs}</div>
            <div className={styles.content}>{React.Children.toArray(children)[current].props.children}</div>
        </div>
    );
}

export default Tabs;
