import { memo, useRef } from 'react';
import MonacoEditor from 'react-monaco-editor';
import editorService from '@/services/EditorService';
import styles from './Editor.module.scss';
import Navigation from './Navigation';

const Editor = memo(() => {
    // const [code, setCode] = useState('');
    const editorRef = useRef();
    const monacoRef = useRef();

    return (
        <div className={styles.wrapper}>
            <Navigation />
            <div className={styles.body}>
                <MonacoEditor
                    language="javascript"
                    theme="vs-dark"
                    // value={code}
                    options={{
                        theme: 'vs-dark',
                        fontSize: 14,
                        lineHeight: 18,
                        wordWrap: 'on', // this breaks TS but it's correct
                        minimap: {
                            enabled: false,
                        },
                        language: 'typescript',
                        scrollBeyondLastLine: true,
                        fontFamily: 'var(--font-code)',
                        fontLigatures: false,
                        lineNumbersMinChars: 3,
                    }}
                    onChange={() => {}}
                    editorDidMount={(editor, monaco) => {
                        editorService.setEditor(editor, monaco);

                        editorRef.current = editor;
                        monacoRef.current = monaco;

                        window.editor = editor;
                        window.monaco = monaco;
                    }}
                />
            </div>
        </div>
    );
});

export default Editor;
