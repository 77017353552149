import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Info.module.scss';
import { faCodeFork, faEye } from '@fortawesome/free-solid-svg-icons';

function Info({ project }) {
    return (
        <div className={styles.wrapper}>
            <p className={styles.name}>{project?.title}</p>
            <p className={styles.desc}>{project?.description}</p>
            <div className={styles.info}>
                <div className={styles.item}>
                    <FontAwesomeIcon icon={faEye} className={styles.icon} />
                    {project?.views_count} view
                </div>
                <div className={styles.item}>
                    <FontAwesomeIcon icon={faCodeFork} className={styles.icon} />
                    {project?.forks_count} fork
                </div>
            </div>
        </div>
    );
}

export default Info;
