import { useRef } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Form.module.scss';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Select({ selected, options = [], onChange = () => {} }) {
    const current = options.find((option) => option.value === selected);
    const innerRef = useRef();

    const handleChange = (value) => {
        onChange(value);
        innerRef.current.blur();
    };

    return (
        <div className={styles.select}>
            <div ref={innerRef} className={styles.inner} tabIndex={7}>
                <span className={styles.value}>{current.label}</span>
                <FontAwesomeIcon icon={faChevronDown} className={styles.icon} />
            </div>
            <ul className={styles.options} onMouseDown={(e) => e.preventDefault()}>
                {options.map((option) => (
                    <li
                        className={clsx(styles.option, { [styles.selected]: current.value === option.value })}
                        key={option.value}
                        onClick={() => handleChange(option.value)}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Select;
