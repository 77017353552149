import clsx from 'clsx';
import styles from './ActivityBar.module.scss';
import { FileIcon, SearchIcon, SettingIcon } from './icons';

const tabs = [
    {
        title: 'Project',
        type: 'project',
        icon: <FileIcon />,
    },
    {
        title: 'Search',
        type: 'search',
        icon: <SearchIcon />,
    },
    {
        title: 'Settings',
        type: 'settings',
        icon: <SettingIcon />,
    },
];

function ActivityBar() {
    return (
        <aside className={styles.wrapper}>
            {tabs.map((tab) => (
                <button key={tab.type} className={clsx(styles.btn, { [styles.active]: false })}>
                    {tab.icon}
                </button>
            ))}
        </aside>
    );
}

export default ActivityBar;
