import { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCheck, faEyeSlash, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

import { Label, FormRow, TextInput, Button, Radio, AutoComplete, Select } from '../../Form';
import styles from './ShareModal.module.scss';

const visibilities = [
    {
        label: 'Public',
        type: 'public',
        icon: faLockOpen,
    },
    {
        label: 'Secret (accessible by URL)',
        type: 'secret',
        icon: faEyeSlash,
    },
    {
        label: 'Private',
        type: 'private',
        icon: faLock,
        requiredLabel: 'PRO USER REQUIRED',
    },
];

function ShareTab({ project }) {
    const [copied, setCopied] = useState(false);
    const [currentVisibility, setCurrentVisibility] = useState(project.visibility);
    const [defaultFile, setDefaultFile] = useState('styles.css');
    const [shareLayout, setShareLayout] = useState('editor-preview');

    const urlRef = useRef();

    const shareUrl = 'https://code.f8team.dev/edit/api-starter-gxrszq?file=README.md';

    useEffect(() => {
        let id;
        if (copied) {
            id = setTimeout(() => setCopied(false), 3000);
        }
        return () => clearTimeout(id);
    }, [copied]);

    const handleCopy = () => {
        setCopied(true);
    };

    return (
        <div className={styles.inner}>
            <div className={styles.left}>
                <Label htmlFor="share-url">Editor URL</Label>
                <FormRow>
                    <TextInput
                        ref={urlRef}
                        id="share-url"
                        readOnly
                        value={shareUrl}
                        onFocus={() => urlRef.current.select()}
                    />
                    <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
                        <Button className={styles.copyBtn} icon={copied ? faCheck : faClipboard}>
                            {copied ? 'Copied' : 'Copy URL'}
                        </Button>
                    </CopyToClipboard>
                </FormRow>

                <Label>Visibility</Label>
                {visibilities.map((visibility) => (
                    <Radio
                        key={visibility.type}
                        {...visibility}
                        checked={visibility.type === currentVisibility}
                        onChange={() => setCurrentVisibility(visibility.type)}
                    />
                ))}
            </div>
            <div className={styles.right}>
                <Label>Default file to show</Label>
                <AutoComplete
                    value={defaultFile}
                    options={['index.html', 'styles.css', 'main.js']}
                    onChange={(option) => setDefaultFile(option)}
                />

                <Label>Editor layout</Label>
                <Select
                    selected={shareLayout}
                    options={[
                        {
                            label: 'Editor & preview',
                            value: 'editor-preview',
                        },
                        {
                            label: 'Editor only',
                            value: 'editor-only',
                        },
                    ]}
                    onChange={setShareLayout}
                />
            </div>
        </div>
    );
}

export default ShareTab;
