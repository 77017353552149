// import { useEffect } from 'react';
// import { toast } from 'react-toastify';
import styles from './Dependency.module.scss';
// import FailedToast from './FailedToast';

const depens = [
    {
        name: 'react',
        version: '18.1.0',
    },
    {
        name: 'react-dom',
        version: '18.1.0',
    },
];

function Dependency() {
    // useEffect(() => {
    //     const id = toast.loading('Intalling dependencies');

    //     const functionThatReturnPromise = () => new Promise((resolve, reject) => setTimeout(resolve, 3000));
    //     functionThatReturnPromise()
    //         .then(() => {
    //             toast.update(id, {
    //                 render: 'Installation completed',
    //                 type: 'success',
    //                 isLoading: false,
    //                 autoClose: 1000,
    //             });
    //         })
    //         .catch(() => {
    //             toast.update(id, {
    //                 render: <FailedToast depName="react-router-dom" onRequestClose={() => toast.dismiss(id)} />,
    //                 type: 'error',
    //                 isLoading: false,
    //             });
    //         });
    // }, []);

    return (
        <div className={styles.wrapper}>
            <ul className={styles.list}>
                {depens.map((depen) => (
                    <li key={depen.name}>
                        <a className={styles.name} href="/">
                            {depen.name}
                        </a>
                        <span className={styles.version}>{depen.version}</span>
                    </li>
                ))}
            </ul>
            <div className={styles.searchWrap}>
                <input className={styles.input} placeholder="Enter package name" />
            </div>
        </div>
    );
}

export default Dependency;
