import { Link } from 'react-router-dom';
import styles from './Home.module.scss';

function Home() {
    return (
        <div className={styles.wrapper}>
            {/* Header */}
            <header className={styles.header}>
                {/* Logo */}
                <div className={styles.logoBox}>
                    <img
                        className={styles.logo}
                        src="https://htmlcss.fullstack.edu.vn/assets/f8_icon.png"
                        alt="F8 IDE"
                    />
                    <span>F8 CODE</span>
                </div>

                {/* Navbar */}
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <Link to={'/'}>Trang chủ</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Khóa học</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Liên hệ</Link>
                        </li>
                    </ul>
                </nav>

                {/* Action */}
                <div className={styles.action}>
                    <ul>
                        <li>
                            <a href="/">Đăng ký</a>
                        </li>
                        <li>
                            <a className={styles.loginBtn} href="/">
                                Đăng nhập
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            {/* Hero */}
            <section className={styles.hero}>
                <section className={styles.left}>
                    <h1 className={styles.heading}>
                        1 click.
                        <br />
                        Tạo dự án.
                        <br />
                        <strong>Coding ngay.</strong>
                    </h1>

                    <p className={styles.desc}>
                        Tạo dự án tức thì để chạy thử những đoạn code, làm bài tập, làm dự án, vv. Không cần các thao
                        tác pull, push, vv như khi dùng Git.
                        <br />
                        <br />
                        Hoàn toàn miễn phí.
                    </p>
                </section>
                <div className={styles.right}>
                    <h2 className={styles.subHeading}>Tạo dự án mới</h2>

                    <div className={styles.projectList}>
                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>

                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>

                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>

                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>

                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>

                        <Link to="/edit/123" className={styles.projectItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" height="2.8rem" viewBox="0 0 16 16">
                                <path d="M8 0L.5 2.59l1.13 9.58L8 15.57l6.37-3.4 1.13-9.58L8 0z" fill="#DD0031"></path>
                                <path d="M8 0v1.73-.02 13.86l6.37-3.4 1.13-9.58L8 0z" fill="#C3002F"></path>
                                <path
                                    d="M8 1.71L3.3 11.87h1.76L6 9.6h4l.92 2.27h1.76L8 1.71zm1.37 6.5H6.63L8 5.02l1.37 3.2z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            <div className={styles.info}>
                                <h3 className={styles.projectTitle}>Angular</h3>
                                <p className={styles.projectDesc}>TypeScript</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
