import { memo, useRef, useEffect } from 'react';
import { Terminal as XTermTerminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { FitAddon } from 'xterm-addon-fit';
import '@/styles/xterm.scss';
import styles from './Terminal.module.scss';
import webContainerService from '@/services/WebContainerService';

const Terminal = memo(() => {
    const xtermTerminal = useRef();
    const terminalRef = useRef();

    /**
     * Load terminal
     */
    useEffect(() => {
        xtermTerminal.current = new XTermTerminal({
            convertEol: true,
            fontSize: 13,
        });
        const fitAddon = new FitAddon();
        xtermTerminal.current.loadAddon(fitAddon);
        xtermTerminal.current.loadAddon(new WebLinksAddon());
        xtermTerminal.current.open(terminalRef.current);
        fitAddon.fit();

        webContainerService.setTerminal(xtermTerminal.current);

        document.addEventListener('terminal-fit', () => {
            fitAddon.fit();
        });
    }, []);

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <h2 className={styles.label}>Terminal</h2>
            </header>
            <div ref={terminalRef} id="terminal" className={styles.terminal}></div>
        </div>
    );
});

export default Terminal;
