import { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './Form.module.scss';

const TextInput = forwardRef(({ error, ...props }, ref) => {
    return (
        <div className={styles.textInputWrap}>
            <input
                ref={ref}
                className={clsx(styles.textInput, { [styles.error]: error })}
                spellCheck="false"
                {...props}
            />
            {!!error && <p className={styles.message}>{error}</p>}
        </div>
    );
});

export default TextInput;
