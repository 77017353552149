import { useEffect, useRef, useState } from 'react';
import { faChevronDown, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Button from '../Button';
import styles from './ProjectTitle.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProjectTitle({ title: _title, onEditInfo }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [title, setTitle] = useState(_title);
    const inputRef = useRef();

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditMode]);

    const handleEditMode = () => {
        setIsEditMode(true);
    };

    const handleBlur = () => {
        setIsEditMode(false);
    };

    return (
        <div className={styles.wrapper}>
            <button className={clsx(styles.btn, { [styles.hide]: isEditMode })} onClick={handleEditMode}>
                {title}
            </button>
            <input
                ref={inputRef}
                className={clsx(styles.input, { [styles.show]: isEditMode })}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={handleBlur}
                spellCheck="false"
            />
            <Button icon={faEyeSlash} className={clsx({ [styles.hide]: isEditMode })} onClick={onEditInfo}>
                <FontAwesomeIcon icon={faChevronDown} className={styles.chevronDown} />
            </Button>
        </div>
    );
}

export default ProjectTitle;
