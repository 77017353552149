import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from '@/redux/Provider';
import reportWebVitals from './reportWebVitals';
import { Provider as WebContainerProvider } from '@/context/WebContainer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider>
        <WebContainerProvider>
            <App />
        </WebContainerProvider>
    </Provider>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
