import clsx from 'clsx';
import styles from './Form.module.scss';
import { useRef } from 'react';

const defaultFn = () => {};

function AutoComplete({ options = [], value, onChange = defaultFn }) {
    const inputRef = useRef();

    const handleChange = (option) => {
        onChange(option);
        inputRef.current.blur();
    };

    return (
        <div className={styles.autoComplete}>
            <input
                ref={inputRef}
                className={styles.input}
                autoComplete="off"
                value={value}
                placeholder="Search for file..."
                onChange={(e) => onChange(e.target.value)}
            />
            <ul className={styles.options} onMouseDown={(e) => e.preventDefault()}>
                {options
                    .filter((option) => option.includes(value))
                    .map((option) => (
                        <li
                            key={option}
                            className={clsx(styles.option, { [styles.selected]: option === value })}
                            onClick={() => handleChange(option)}
                        >
                            {option}
                        </li>
                    ))}
            </ul>
        </div>
    );
}

export default AutoComplete;
