import { useCollapse } from 'react-collapsed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './Collapsed.module.scss';

function Collapsed({ title, children, defaultExpanded }) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        defaultExpanded,
        hasDisabledAnimation: true,
    });
    return (
        <>
            <button className={styles.wrapper} {...getToggleProps()}>
                <span className={styles.icon}>
                    <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
                </span>
                {title}
            </button>
            <section {...getCollapseProps()}>{children}</section>
        </>
    );
}

export default Collapsed;
