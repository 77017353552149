import { memo, useEffect, useRef } from 'react';
import { Tree, useSimpleTree } from 'react-arborist';
import TreeItem from './TreeItem';
import styles from './FileManager.module.scss';

function sortChildren(node) {
    if (!node.children) return node;
    const copy = [...node.children];
    copy.sort((a, b) => {
        if (!!a.children && !b.children) return -1;
        if (!!b.children && !a.children) return 1;
        return a.name < b.name ? -1 : 1;
    });
    const children = copy.map(sortChildren);
    return { ...node, children };
}

function useTreeSort(data) {
    return data.map(sortChildren);
}

const defaultFn = () => {};

const FileManager = memo(({ data = [], onMove = defaultFn }) => {
    const [files, controls] = useSimpleTree(data);
    const isReady = useRef();

    useEffect(() => {
        isReady.current && requestIdleCallback(() => onMove(files));
        isReady.current = true;
    }, [files, onMove]);

    useEffect(() => {
        const wrapper = document.querySelector(`.${styles.wrapper}`);
        if (wrapper) {
            wrapper.parentElement.style.height = 'auto';
        }
    }, []);

    const handleMove = (args) => {
        args.dragNodes.forEach((node) => {
            node.data.parent_id = args.parentId;
        });
        controls.onMove(args);
    };

    return (
        <Tree
            className={styles.wrapper}
            data={useTreeSort(files)}
            openByDefault={false}
            initialOpenState={{ [files[0].id]: true }}
            width="100%"
            indent={10}
            rowHeight={22}
            padding={10}
            onMove={handleMove}
            renderCursor={() => null}
        >
            {TreeItem}
        </Tree>
    );
});

export default FileManager;
